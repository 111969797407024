/*
Archivo de configuración donde se asocia una acción [key] con el nombre [valor]
Los valores que asignemos simplemente son nombres cortos de las pantallas para que la app las use internamente,
Las navegaciones no se realizan en base a paths completos si no a nombres cortos
*/

//BLOQUES DE RUTAS
//  1→ AGENTE
//  2→ ACCESO B2B PARA CLIENTE DESDE SMS o EMAIL DE CONTINUE ENVIADO POR EL AGENTE
//  3→ ACCESO B2C PARA CLIENTE DIRECTO
//  4→ CLIENTE
const routes = {
	loginAgent: 'loginAgent',
	dashboard: 'dashboard',
	simulationDataAgent: 'simulationDataAgent',
	clientDataAgent: 'clientDataAgent',
	simulationAgent: 'simulationAgent',
	followUp: 'followUp',

	continue: 'continue',
	loadTarget: 'loadTarget',

	loginClient: 'seguro-vida',
	startOnboarding: 'startOnboarding',

	simulationData: 'simulationData',
	simulation: 'simulation',
	simulationFamilyCalculator: 'simulationFamilyCalculator',
	simulationFinancialCalculator: 'simulationFinancialCalculator',
	simulationEmploymentCalculator: 'simulationEmploymentCalculator',

	clientData: 'clientData',

	pbc: 'pbc',
	health: 'health',

	bankAccount: 'bankAccount',

	identityBiometricUpsert: 'identityBiometricUpsert',
	identityBiometricSelectDoc: 'identityBiometricSelectDoc',
	identityBiometric: 'identityBiometric',
	IdentityBiometricAttach: 'IdentityBiometricAttach',
	identityBiometricExtraDocs: 'identityBiometricExtraDocs',

	activateProduct: 'activateProduct',

	signatureSteps: 'signatureSteps',
	signatureRead: 'signatureRead',
	signatureContract: 'signatureContract',

	productCreationFinishedB2C: 'productCreationFinishedB2C',

	productCreationFinishedB2B: 'productCreationFinishedB2B',
	productCreationFinishedManual: 'productCreationFinishedManual',
	cannotConfirm: 'cannotConfirm',

	KYCidentityBiometricUpsert: 'KYCidentityBiometricUpsert',
	KYCidentityBiometricSelectDoc: 'KYCidentityBiometricSelectDoc',
	KYCidentityBiometric: 'KYCidentityBiometric',
	KYCidentityBiometricFinished: 'KYCidentityBiometricFinished'
};

export default routes;
