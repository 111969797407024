import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	Button,
	IdentityDocumentsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';

import HorizontalProgressBar from '../../components/HorizontalProgressBar/HorizontalProgressBar';
import routes from '../../config/routes';
import { DashedLines } from 'components/DashedLines/DashedLines';
import { Faq } from 'components/FAQ/Faq';
import Header from 'layout/components/Header/Header';

import { Wrapper } from 'layout/Wrapper';

export const IdentityBiometric = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	target: {
		sourceWrapper: TargetsWrapper,
		sourcePath: 'targets',
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id'
		}
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	const { dniData, user, target } = PickerHOC.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo, goBack, query } = NavigationWrapper.use();
	const { loadUserIdentityDocuments } = IdentityDocumentsWrapper.use();

	// eslint-disable-next-line no-unused-vars
	const [isB2B, _] = useState(localStorage.getItem('B2Flow') === 'B2B' ? true : false || false);

	console.log('👉 dniData', dniData);

	const pendingFlow = useMemo(
		() => dniData.verificationStatus === 'NOT_CREATED' || dniData.verificationStatus === 'PENDING',
		[dniData]
	);
	const successFlow = useMemo(() => dniData.verificationStatus === 'VALID', [dniData]);
	const errorFlow = useMemo(() => dniData.verificationStatus === 'NOT_VALID', [dniData]);

	const [scannedDNI, setscannedDNI] = useState('');
	const [sameBenefDNI, setsameBenefDNI] = useState(false);
	const [diferentToReceived, setdiferentToReceived] = useState(false);

	console.log('🚩 scannedDNI', scannedDNI);
	console.log('🚩 sameBenefDNI', sameBenefDNI);
	console.log('🚩 diferentToReceived', diferentToReceived);

	// IDENTITY MOCK
	const esMock = false;

	const intervalTime = 5000; // Tiempo en milisegundos (5 segundo)
	useEffect(() => {
		if (!pendingFlow) return; // Si ya se alcanzó el valor, no hacer nada

		const interval = setInterval(() => {
			console.log('🎯 loadUserIdentityDocuments');
			loadUserIdentityDocuments(user.id);
		}, intervalTime);

		return () => clearInterval(interval); // Limpia el intervalo cuando cambie la dependencia

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dniData.verificationStatus]);

	console.log('👉 pendingFlow', pendingFlow);
	console.log('👉 successFlow', successFlow);
	console.log('👉 errorFlow', errorFlow);

	useEffect(() => {
		const reviewSuccess = async () => {
			// Respuesta OK, continuamos con lo que venga
			const scannedDNIeffect = dniData?.number;
			setscannedDNI(scannedDNIeffect);

			const sameBenefDNIeffect =
				target?.creationData?.details?.beneficiaryType !== 'default' &&
				target?.creationData?.details?.beneficiaries?.find(
					(be) => be?.dni.toUpperCase() === scannedDNIeffect.toUpperCase()
				);
			setsameBenefDNI(sameBenefDNIeffect ? true : false);

			const diferentToReceivedeffect = esMock
				? false
				: user?.metadata?.document && scannedDNIeffect?.toUpperCase() !== user?.metadata?.document?.toUpperCase();
			setdiferentToReceived(diferentToReceivedeffect ? true : false);

			if (sameBenefDNIeffect) {
			} else if (diferentToReceivedeffect) {
			} else {
				navigateTo(routes.activateProduct);
			}
		};
		if (successFlow) {
			reviewSuccess();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [successFlow]);

	// ### DNI PROCESS
	const scanManaggerReference = useRef();
	let currentLang = 'es';
	const country = useMemo(() => (query?.country ? query?.country : 'ESP'), [query]);
	const enableSelfie = useMemo(() => (query?.selfie === 'false' ? false : true), [query]);

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={isMobile} />, []);
	return (
		<Wrapper width={'80%'}>
			<HorizontalProgressBar
				step={4}
				total={6}
				styleWrapper={{ maxWidth: 450 }}
				label={isB2B ? 'Documento de identidad' : ''}
			/>
			{pendingFlow && (
				<IdentityDocumentsWrapper.SimpleScanManager
					ref={scanManaggerReference}
					identityDocumentId={dniData?.id}
					style={{
						marginTop: 30,
						width: '100%',
						height: isMobile ? '500px' : '500px',
						minHeight: isMobile ? '500px' : '500px',
						overflow: 'hidden'
					}}
					scanConfig={{
						providerIdentifier: esMock ? 'mock' : 'tecalis',
						metadata: {
							kyc: {
								excludedDocTypes: [],
								allowedDocTypes: [`${country}_DNI`, `${country}_NIE`, `PASSPORT_${country}`],
								status_report: ['Verification OK'],
								methods: {
									ReadMrz: true,
									VerifyData: true,
									Images: true,
									Liveness: enableSelfie,
									FaceMatch: enableSelfie,
									FacialRecognition: enableSelfie,
									Selfie: false,
									FraudScoring: true,
									ImagesUrl: false,
									StorageUpload: true,
									Otp: true,
									Location: true,
									CheckFacesNumber: true
								},
								fraudScoring: {
									aeat: true,
									expirationDate: true,
									legalAge: true,
									photocopyCheck: true,
									hologramCheck: false
								}
							},
							front: {
								iframe_mode: true,
								kyc_mode: 'auto',
								liveness_guidance_mode: 'smile',
								skin: 'light',
								borders: true,
								borders_percentage: 50,
								borders_width: 50,
								video_mode: 'native',
								css_url: false,
								wait_after_kyc_success: true,
								'no-spinner': false,
								title_kyc: 'Documento de identidad',
								mirror_mode_pc: true,
								location_config: 'optional',
								default_lang: currentLang,
								force_lang: currentLang,
								accept_manual_text: '',
								new_index: false,
								iccid: false,
								msisdn: false,
								barcode_min: false,
								auditoria: true,
								headless_barcode: false,
								prioridad_interrupcion: 'postergado',
								final_imprimible: false,
								adapt_detector_to_passport: true,
								retries_before_manual_pc: 3,
								retries_before_manual_others: 3,
								logs: false
							}
						}
					}}
				/>
			)}
			{sameBenefDNI && (
				<View
					style={{
						marginTop: !isMobile ? 40 : 20,
						maxWidth: !isMobile ? 600 : undefined
					}}
				>
					<Text headingPrimarySt>Se ha encontrado un error en el listado de beneficiarios.</Text>
					<Text headingSecondarySt>No puedes añadirte tú mismo como beneficiario.</Text>
					<Button
						style={{ marginTop: 30, marginBottom: 0, display: 'inline-block' }}
						onClick={() => {
							navigateTo(routes.clientData);
						}}
					>
						Modificar
					</Button>
				</View>
			)}
			{diferentToReceived && (
				<View
					style={{
						marginTop: !isMobile ? 40 : 20,
						maxWidth: !isMobile ? 600 : undefined
					}}
				>
					<Text headingPrimarySt errorSt>
						Se ha encontrado un error en el documento añadido.
					</Text>
					<Text headingTertiarySt errorSt>
						El número del documento escaneado no coincide con el que se indicó al inicio del proceso.
					</Text>
					<Button
						style={{ marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							navigateTo(routes.identityBiometricUpsert);
						}}
					>
						Modificar
					</Button>
				</View>
			)}
			{errorFlow && (
				<>
					<Text headingPrimarySt errorSt>
						❌ Proceso terminado con error
					</Text>
					<Button
						style={{ marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							navigateTo(routes.identityBiometricUpsert);
						}}
					>
						Reintentar
					</Button>
				</>
			)}
			<DashedLines />
			<Faq />
		</Wrapper>
	);
});
