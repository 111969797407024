import React from 'react';
import { isMobile } from 'react-device-detect';

import {
	IdentityDocumentsWrapper,
	NavigationWrapper,
	PickerHOC,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';

import { WrapperKYC } from '../../layout/Wrapper';
import Header from 'layout/components/Header/Header';

export const KYCidentityBiometricFinished = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	const { dniData } = PickerHOC.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { goBack } = NavigationWrapper.use();

	console.log('👉 dniData', dniData);

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={true} />, []);

	return (
		<WrapperKYC width={'80%'} widthMobile={'100%'}>
			<View>
				<Text headingPrimarySt errorSt>
					✅ Redirect Correcto
				</Text>
			</View>
		</WrapperKYC>
	);
});
