import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	Button,
	IdentityDocumentsWrapper,
	NavigationWrapper,
	PickerHOC,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';

import routes from '../../config/routes';
import { WrapperKYC } from '../../layout/Wrapper';
import VanillaJSONEditor from '../../libs/VanillaJSONEditor';
import 'aliceonboarding/dist/aliceonboarding.css';
import Header from 'layout/components/Header/Header';

export const KYCIdentityBiometric = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	const baseUrl = window.location.origin;
	const { dniData, user } = PickerHOC.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo, goBack, query } = NavigationWrapper.use();
	const { lastMainScanId, loadUserIdentityDocuments } = IdentityDocumentsWrapper.use();

	console.log('👉 dniData', dniData);
	console.log('👉 lastMainScanId', lastMainScanId);

	const pendingFlow = useMemo(
		() => dniData.verificationStatus === 'NOT_CREATED' || dniData.verificationStatus === 'PENDING',
		[dniData]
	);
	const successFlow = useMemo(() => dniData.verificationStatus === 'VALID', [dniData]);
	const errorFlow = useMemo(() => dniData.verificationStatus === 'NOT_VALID', [dniData]);

	const intervalTime = 5000; // Tiempo en milisegundos (5 segundo)
	useEffect(() => {
		if (!pendingFlow) return; // Si ya se alcanzó el valor, no hacer nada

		const interval = setInterval(() => {
			console.log('🎯 loadUserIdentityDocuments');
			loadUserIdentityDocuments(user.id);
		}, intervalTime);

		return () => clearInterval(interval); // Limpia el intervalo cuando cambie la dependencia

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dniData.verificationStatus]);

	console.log('👉 pendingFlow', pendingFlow);
	console.log('👉 successFlow', successFlow);
	console.log('👉 errorFlow', errorFlow);

	useEffect(() => {
		if (successFlow) {
			navigateTo(routes.KYCidentityBiometricFinished);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [successFlow]);

	// ### DNI PROCESS
	const scanManaggerReference = useRef();
	let currentLang = 'es';
	const country = useMemo(() => (query?.country ? query?.country : 'ESP'), [query]);
	const enableSelfie = useMemo(() => (query?.selfie === 'false' ? false : true), [query]);

	const [showEditor, setShowEditor] = useState(false);

	useLoading(undefined, { loadingMessage: undefined });
	useHeader(<Header goBack={isMobile ? goBack : undefined} mobile={true} />, []);
	return (
		<WrapperKYC width={'80%'} widthMobile={'100%'}>
			{successFlow ? (
				<Text headingPrimarySt errorSt>
					✅ Proceso terminado correctamente
				</Text>
			) : errorFlow ? (
				<Text headingPrimarySt errorSt>
					❌ Proceso terminado con error
				</Text>
			) : null}

			{successFlow || errorFlow ? (
				<>
					<Button
						selectedSt
						style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							setShowEditor(showEditor ? false : true);
						}}
					>
						{showEditor ? 'Ocultar reporte' : 'Ver reporte'}
					</Button>

					{showEditor && (
						<View className="my-editor" style={{ minWidth: '100%', padding: 10 }}>
							<VanillaJSONEditor
								content={{
									json: dniData,
									text: undefined
								}}
								mode="tree"
								mainMenuBar={false}
								navigationBar={false}
								statusBar={false}
								readOnly={true}
							/>
						</View>
					)}

					<Button
						style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true');
						}}
					>
						Iniciar nuevo proceso con selfie y documento de identidad español
					</Button>
					<Button
						style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
						onClick={() => {
							window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true&selfie=false');
						}}
					>
						Iniciar nuevo proceso sin selfie y documento de identidad español
					</Button>
					{/* <Button
												style={{ width: '100%', marginTop: 30, marginBottom: 0 }}
												onClick={() => {
													window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true&docType=residencePermit');
												}}
											>
												Iniciar nuevo proceso con selfie y permiso de residencia español
											</Button> */}
					<Button
						style={{ width: '100%', marginTop: 30, marginBottom: 60 }}
						onClick={() => {
							window.location.replace(window.location.origin + '/caser/seguro-vida?kyc=true&selfie=false&country=PRT');
						}}
					>
						Iniciar nuevo proceso sin selfie y documento de identidad portugués
					</Button>
				</>
			) : (
				<IdentityDocumentsWrapper.SimpleScanManager
					ref={scanManaggerReference}
					identityDocumentId={dniData?.id}
					style={{
						// marginTop: 30,
						width: '100%',
						height: '500px'
						// minHeight: isMobile ? '500px' : '750px',
						// overflow: 'hidden',
					}}
					scanConfig={{
						providerIdentifier: 'tecalis',
						metadata: {
							kyc: {
								excludedDocTypes: [],
								allowedDocTypes: [`${country}_DNI`, `${country}_NIE`, `PASSPORT_${country}`],
								status_report: ['Verification OK'],
								methods: {
									ReadMrz: true,
									VerifyData: true,
									Images: true,
									Liveness: enableSelfie,
									FaceMatch: enableSelfie,
									FacialRecognition: enableSelfie,
									Selfie: false,
									FraudScoring: true,
									ImagesUrl: false,
									StorageUpload: true,
									Otp: true,
									Location: true,
									CheckFacesNumber: true
								},
								fraudScoring: {
									aeat: true,
									expirationDate: true,
									legalAge: true,
									photocopyCheck: true,
									hologramCheck: false
								}
							},
							front: {
								iframe_mode: true,
								kyc_mode: 'auto',
								liveness_guidance_mode: 'smile',
								skin: 'light',
								// additional_logo: 'https://',
								borders: true,
								borders_percentage: 50,
								borders_width: 50,
								video_mode: 'native',
								// max_dni_width: 800,
								// redirect: `${baseUrl}/caser/internal/KYCidentityDocuments/KYCidentityBiometricFinished`,
								// redirect_ko: `${baseUrl}/caser/internal/KYCidentityDocuments/KYCidentityBiometricFinished`,
								css_url: false,
								// 'theme-color': '#FFFFFF',
								wait_after_kyc_success: true,
								'no-spinner': false,
								title_kyc: 'Documento de identidad',
								mirror_mode_pc: true,
								// blur_mrz: false,
								// blur_anverso: false,
								// no_thanks_name: false,
								location_config: 'optional',
								default_lang: currentLang,
								force_lang: currentLang,
								// back_button_url: `${baseUrl}/caser/internal/KYCidentityDocuments/KYCidentityBiometricUpsert`,
								// gyroscope_selfie_check: false,
								accept_manual_text: '',
								// upload_files: [],
								new_index: false,
								// policy_checks: [],
								// fallback: false,
								// modal_postergado_text: '',
								// device_only: '',
								// android_browsers: [],
								iccid: false,
								msisdn: false,
								barcode_min: false,
								auditoria: true,
								headless_barcode: false,
								prioridad_interrupcion: 'postergado',
								final_imprimible: false,
								adapt_detector_to_passport: true,
								retries_before_manual_pc: 3,
								retries_before_manual_others: 3,
								logs: false
							}
						}
					}}
				/>
			)}
		</WrapperKYC>
	);
});
